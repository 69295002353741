.header {
  padding: 2rem;
  border-bottom: 1px solid rgba(34, 36, 38, .15);

  .row {
    display: flex;
    justify-content: space-between;

    .sort {
      margin-top: 1rem;
      display: flex;
      align-items: center;

      .dropdown {
        display: flex;
        align-items: center;

        &>label {
          font-weight: 500;
          margin-right: 0.5rem;
        }
      }

      .toggle {
        margin-left: 1rem;
        display: flex;
        align-items: center;

        &>label {
          font-weight: 500;
          margin-left: 0.5rem;
        }
      }
    }
  }


}

.content {
  padding: 3rem 2rem;
  max-height: 60vh;
  overflow-y: auto;
}

.actions {
  background: #f9fafb;
  padding: 2rem;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  text-align: right;

  display: flex;
  justify-content: flex-end;
}