.menu {
  display: block !important;
}

/* Fixed layout for smaller devices */
@media only screen and (max-width: 991px) {
  .menu {
    position: static !important;
  }
}

.help {
  color: #2b76c6 !important;
}
.help :global(.icon) {
  margin: 0 0 0 0.35714286em !important;
}
