.modal {
  top: 50%;
  transform: translateY(-50%);
}

.header {
  padding: 1.25rem 1.5rem;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  font-size: larger;

  i {
    margin-right: 0.5rem;
  }
}
