.main {
  display: inline-flex;
  align-items: center;
  margin-right: 4rem;
}
.label {
  flex: 0 0 auto;
}
.control {
  flex: 1 1 0px;
  display: flex;
  margin-left: 1rem;
}
.radio:not(:first-child) {
  margin-left: 1rem;
}
