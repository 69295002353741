.main {
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
}
.header {
  background: #f3f4f5;
  padding: 0.5rem 1rem;
}
.body {
  padding: 1rem;
}
.body > div:not(:first-child) {
  margin-top: 0.75rem;
}
