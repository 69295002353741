 .header {
   color: rgba(0, 0, 0, .85);
   border-bottom: 1px solid rgba(34, 36, 38, .15);
   padding: 1.25rem 1.5rem;

   .title {
     margin: 0;
   }

   .description {
     margin-top: 0.75rem;
   }
 }

 .content {
   padding-top: 0 !important;
   padding-bottom: 0 !important;
 }

 .body {
   display: flex;

   .left,
   .right {
     flex: 1 1 0px;
     padding-top: 1.5rem;
     padding-bottom: 1.5rem;
   }

   .left {
     padding-right: 2rem;
     border-right: 1px solid rgba(34, 36, 38, .15);
   }

   .right {
     padding-left: 2rem;
   }


   .list {
     margin-top: 1rem;
     padding-left: 1rem;
   }

   .item {
     padding-bottom: 1rem !important;
   }

   .item:last-child {
     padding-bottom: 0 !important;
   }

   .item-inner {
     display: flex;
     align-items: center;

     .label {
       flex: 0 0 60%;
     }

     .cta {
       flex: 0 0 auto;
     }

     .note {
       margin-top: 0.25rem;
       font-size: smaller;
       color: rgba(0, 0, 0, 0.6);
     }
   }


 }