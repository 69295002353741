.fluid {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.battery {
  white-space: nowrap;
  margin-left: 4px;
}
