.flex {
  display: flex;
}

.direction-column {
  flex-direction: column;
}
.direction-column-reverse {
  flex-direction: column-reverse;
}

.justify-flex-start {
  justify-content: flex-start;
}
.justify-flex-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-space-between {
  justify-content: space-between;
}

.align-flex-start {
  align-items: flex-start;
}
.align-flex-end {
  align-items: flex-end;
}
.align-center {
  align-items: center;
}

.grow-1 {
  flex: 1 1 0;
}
.grow-2 {
  flex: 2 1 0;
}
.grow-3 {
  flex: 3 1 0;
}
.grow-auto {
  flex: 0 0 auto;
}
