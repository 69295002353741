.file-name {
  display: flex;
  position: relative;
  padding-left: 2.5rem;

  .flag {
    position: absolute;
    top: -1.2rem;
    left: 0;
    height: calc(100% + 2.4rem);

    display: flex;
    align-items: center;
    justify-content: center;
    color: #1ebc30;
    background-color: #e5f9e7;
    padding: 0 0.25rem;
    margin-right: 1rem;

    i {
      margin: 0;
    }
  }
}

.actions {
  display: flex;
  justify-content: flex-end;

  .download,
  .progress,
  .cancelled,
  .failed,
  .queued {
    flex: 0 0 12rem;
  }

  .btn1 {
    flex: 0 0 auto;
    margin-left: 1.5rem;
  }

  .btn2 {
    flex: 0 0 auto;
    margin-left: 1rem;
  }

  .cancelled,
  .failed,
  .queued {
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      margin-left: 0.5rem;
    }
  }

  .failed {
    color: #db2828;
  }

  .queued {
    color: #2185d0;
  }

  .progress-bar {
    margin: 0;
  }

  .progress-label {
    margin: 0.5rem 0 0 0;
    text-align: center;
  }
}

.error-message {
  font-size: larger;
  color: rgba(0, 0, 0, .87);
  margin-bottom: 0.5rem;
}

.error-description {
  color: rgba(0, 0, 0, .87);
}

.error-image {
  margin-top: 2rem;
  height: 8rem;
}