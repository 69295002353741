.main {
  padding: 3.5rem 1rem 0.5rem 2rem;
  position: relative;

  .fields {
    display: flex;
    margin-top: 1.75rem;

    .field {
      margin-left: 4rem;
      &:first-child {
        margin-left: 0;
      }

      .label {
        display: flex;

        .text {
          margin: 0;
        }

        .tooltip {
          cursor: pointer;
          position: relative;
          margin-left: 0.25rem;
          top: -0.25rem;
          color: #bccddf;
          &:hover {
            color: #a5bacb;
          }
        }
      }

      .element {
        margin-top: 0.75rem;

        :global(.item) {
          padding: 0 !important;
          margin-top: 0.5rem !important;
          &:first-child {
            margin-top: 0 !important;
          }
        }

        :global(.ui.list .list) {
          padding-top: 0 !important;
        }
      }
    }
  }

  .warning {
    display: inline-block;
    max-width: calc(100% - 14rem);
    position: absolute;
    bottom: 0.5rem;

    color: #5c5c5c !important;

    svg {
      color: #f2711c;
    }

    span {
      margin-left: 1rem;
    }
  }

  .button {
    margin-top: 3rem;
    display: flex;
    justify-content: flex-end;
    cursor: auto;

    .disabled,
    .disabled:hover {
      background: #e0e1e2 none;
      color: rgba(0, 0, 0, 0.6);
      opacity: 0.45 !important;
    }
  }
}
