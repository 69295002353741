.main {
  padding: 2rem;
  position: relative;
}

.title {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.close {
  position: absolute;
  top: 1rem;
  right: 2rem;
  background: none;
  border: none;
  font-size: 2rem;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}
.close:hover {
  color: rgba(0, 0, 0, 0.8);
}
