.field {
  display: flex;
}
.field:not(:first-child) {
  margin-top: 1rem;
}
.label {
  width: 14rem;
  flex: 0 0 auto;
  color: rgba(0, 0, 0, 0.6);
}
.value {
  flex: 1 1 0px;
}
