.main .label {
  font-weight: 600;
}
.main .value {
  padding-top: 0.25em;
}

.mainInline {
  display: flex;
}
.mainInline .label {
  font-weight: 600;
  flex: 0 0 auto;
  margin-right: 1rem;
}
.mainInline .value {
  flex: 1 1 0px;
}
