 .patient {
   p {
     margin: 0;

     &:not(:first-child) {
       margin-top: 0.25rem;
     }
   }

   .first {
     font-size: smaller;

     &::first-line {
       font-size: larger;
     }
   }

   .last {
     font-size: smaller;
   }

   .phone {
     font-size: smaller;
   }
 }