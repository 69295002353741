.option {
  display: flex;
  align-items: center;

  .left {
    flex: 0 0 70%;
    display: flex;
    align-items: center;

    .name {
      margin-left: 0.78571429rem;
    }
  }

  .right {
    flex: 1 1 0px;
    display: flex;
    align-items: center;

    .attachedPatient {
      flex: 1 1 0px;
      display: flex;

      .inUse {
        color: #f2711c;
      }
      .free {
        color: grey;
      }
    }

    .battery {
      flex: 0 0 auto;
    }
  }
}

.selected {
  padding-top: 0.7em;
  display: flex;

  .status {
    flex: 1 1 0px;
    margin-right: 0.75rem;
  }

  .attachedPatient {
    flex: 1 1 0px;
    margin-right: 0.75rem;

    .inUse {
      color: #f2711c;
    }
    .free {
      color: grey;
    }

    .text {
      margin-left: 0.25rem;
    }
  }

  .batter {
    flex: 0 0 auto;
  }
}
