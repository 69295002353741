.button {
  position: absolute;
  z-index: 1;
  cursor: pointer;
  border: none;
  background: none;

  color: #798fa6;
  i {
    color: #bccddf;
  }

  &:hover {
    color: #437ca7;
  }

  i {
    margin-right: 0.5rem;
  }
}
