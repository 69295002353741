.main {
  display: flex;
  justify-content: space-between;

  .left {
    display: flex;

    .export {
      margin-right: 1rem;

      & button svg {
        margin-right: 0.5rem;
      }
    }

    .dropdown {
      margin-right: 1rem;
      & > label {
        margin-right: 0.25rem;
      }
    }

    .toggle {
      margin-top: 0.5rem;

      display: flex;
      & > label {
        margin-left: 0.5rem;
      }
    }
  }
}
