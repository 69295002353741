.note {
  margin-left: 1.6rem;
  font-size: smaller;
}
.warning,
.warning2 {
  font-size: smaller;
  background-color: #ff7f50;
  border-radius: 0.28571429rem;
  padding: 0.1em 0.6em;
  margin-top: 0.2em;
  display: inline-flex;
}

.main2 {
  text-align: right;
}
