.main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 0 1rem 1rem;

  .header {
    margin: 0;
  }

  .button {
    svg {
      margin-right: 0.5rem;
    }
  }
}