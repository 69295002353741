.group {
  display: flex;
}
.group:not(:first-child) {
  margin-top: 0.75rem;
}
.group > * {
  flex: 1 1 0px;
  margin-right: 1rem;
}
.group > *.overflowHidden {
  overflow: hidden;
}
.group > *:last-child {
  margin-right: 0;
}
.group > .two {
  flex: 2 1 0px;
}
.group > .two > *:not(:first-child) {
  margin-top: 0.75rem;
}
.group :global(.ui.input) > input {
  width: 100%;
}

.pre {
  white-space: pre-line;
}

.sensor {
  display: flex;
  align-items: center;
}
.sensor > div:first-child {
  flex: 1 1 0px;
}
.sensor > div:last-child {
  flex: 0 0 auto;
  margin-left: 0.5em;
}

.active-sensor {
  max-width: 12.2rem;
}
