.row {
  display: flex;
  justify-content: space-between;
}
.left {
  display: flex;
  align-items: center;
}
.left form {
  margin-right: 1rem;
}
