.main {
  width: 50rem;
  margin: 10rem auto;
}

.center {
  text-align: center;
}

.negative {
  color: #912d2b !important;
}

.header {
  color: #912d2b !important;
  margin-bottom: 0.25em !important;
}

.buttons {
  width: 10rem;
  margin: 2rem auto 0;

  button:not(:first-child) {
    margin-top: 1rem;
  }
}
