.wrapper {
  display: flex;
  align-items: center;
}
.list {
  flex: 1 1 0px;
}
.button {
  flex: 0 0 auto;
  margin-left: 0.5rem;
}
