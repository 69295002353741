:global(.ui.message).yellow {
  color: #b58105;
  background-color: #fff8db;
  margin: 0;
  border-radius: 0;
}

:global(.ui.container).message {
  display: flex;
  align-items: center;
  line-height: 1;
}

:global(.ui.container).message > p {
  margin: 0;
  margin-left: 0.25rem;
}

.message2 {
  display: flex;
  text-align: left;
}
