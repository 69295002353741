.main {
  width: 80%;
  margin: 6rem auto;
}

.row {
  display: flex;
}
.left {
  flex: 0 0 auto;
}
.right {
  flex: 1 1 0px;
  margin-left: 3rem;
}

.field {
  display: flex;
  align-items: center;
}
.field:not(:first-child) {
  margin-top: 1rem;
}
.label {
  width: 12rem;
  flex: 0 0 auto;
}
.value {
  flex: 1 1 0px;
}
.button {
  flex: 0 0 auto;
}
