.main {
  display: flex;
  justify-content: center;
  padding-left: 34px;
  padding-right: 28px;
  margin-bottom: 12px;
}
.legend {
  display: inline-flex;
  padding: 6px;
  border: 1px solid rgba(34, 36, 38, 0.15);
}
.row {
  display: flex;
}
.group {
  flex: 0 0 auto;
}
.item {
  display: flex;
  padding: 6px 12px;
}
.icon {
  flex: 0 0 auto;
  height: 10px;
  width: 10px;
  margin-top: 4px;
}
.text {
  flex: 1 1 0px;
  margin-left: 8px;
  font-family: 'Gill Sans', 'Gill Sans MT';
}
