.timeline {
  margin-top: 2rem;
}
.logs {
  margin-top: 1rem;
  max-height: 13rem;
  overflow-y: auto;
}
.log-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.75rem;
  padding-top: 0.5rem;
  cursor: pointer;
}
.log-item .time {
  flex: 0 0 auto;
}
.log-item .status {
  flex: 0 0 6rem;
  margin-left: 1rem;
  position: relative;
  text-align: center;
  top: -0.25rem;
}
.log-item .message {
  flex: 1 1 0px;
  margin-left: 1rem;
  height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.log-item .icon {
  flex: 0 0 auto;
  margin-left: 3rem;
}

.log-item-active .message {
  height: auto;
  white-space: normal;
}
