.group {
  display: flex;
}
.group > * {
  flex: 1 1 0px;
  padding-right: 0.75em;
}
.group > *:last-child {
  padding-right: 0;
}
.group > .two {
  flex: 2 2 0px;
}

.radioHeadline {
  margin-bottom: 1em !important;
}
