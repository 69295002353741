.department {
  margin-bottom: 0.75rem;
}
.name {
  margin: 0;
}
.roles {
  margin: 0;
  color: rgba(0, 0, 0, 0.7);
}
