.plot {
  margin-top: 6px;
  display: inline-flex;
  align-items: flex-end;
  height: 18px;
}
.item {
  flex: 0 0 auto;
  width: 3px;
  margin-left: 1px;
  background: #0e6eb8;
}
.item:first-child {
  margin-left: 0;
}
