 .header {
   color: rgba(0, 0, 0, .85);
   border-bottom: 1px solid rgba(34, 36, 38, .15);
   padding: 1.25rem 1.5rem;

   .title {
     margin: 0;
   }

   .description {
     margin-top: 0.75rem;
   }
 }

 .content {
   padding-top: 0 !important;
   padding-bottom: 0 !important;
 }

 .body {
   display: flex;

   .left,
   .right {
     flex: 1 1 0px;
     padding-top: 3rem;
     padding-bottom: 3rem;
     text-align: center;
   }

   .left {
     padding-right: 2rem;
     border-right: 1px solid rgba(34, 36, 38, .15);
   }

   .right {
     padding-left: 2rem;
   }

 }