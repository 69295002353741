.main {
  position: relative;
}
.status {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
}
.edit {
  position: absolute;
  top: 0;
  right: 0;
}

.row {
  margin-bottom: 1rem;
}
.row:last-child {
  margin-bottom: 0;
}
.row2 {
  margin-bottom: 2rem;
}
.row2:last-child {
  margin-bottom: 0;
}

.group {
  display: flex;
}
.item {
  margin-right: 4rem;
}
.item:last-child {
  margin-right: 0;
}

.field {
  display: flex;
}
.label {
  font-weight: 700;
}
.value {
  margin-left: 1rem;
}
