.wrapper {
  position: relative;
}
.hidden {
  visibility: hidden;
}
.content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
