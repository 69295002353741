.indicator {
  position: relative;
}

.indicator:after {
  content: '';
  position: absolute;
  width: 0.7rem;
  height: 0.7rem;
  top: 0.5rem;
  right: 0.25rem;
  border-radius: 100%;
  background-color: var(--indicator-color);
}

.indicator-yellow {
  --indicator-color: #ffbe0b;
}
.indicator-red {
  --indicator-color: #db2e25;
}
.indicator-blue {
  --indicator-color: #007eb9;
}
