.group {
  display: flex;
}
.group > * {
  flex: 1 1 0px;
  margin-right: 1rem;
}
.group > *:last-child {
  margin-right: 0;
}
.group > .fixed {
  flex: 0 0 auto;
}
.group > .mr {
  margin-right: 1rem;
}

.sensor {
  display: flex;
  align-items: center;
}
.sensor > div:first-child {
  flex: 1 1 0px;
}
.sensor > div:last-child {
  flex: 0 0 auto;
  margin-left: 0.5em;
}
