.react-datepicker__day:not(.react-datepicker__day--selected):not(.react-datepicker__day--outside-month)
  .react-datepicker__day-no-data {
  color: #bababa;
  background-color: #dddddd;
  box-shadow: 0 0 0 2px inset #fffead;
}

/* === */

.react-datepicker__day:not(.react-datepicker__day--selected):not(.react-datepicker__day--outside-month)
  .react-datepicker__day-colored {
  position: relative;
}
.react-datepicker__day:not(.react-datepicker__day--selected):not(.react-datepicker__day--outside-month)
  .react-datepicker__day-colored-tint {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #bed9f4;
}
.react-datepicker__day:not(.react-datepicker__day--selected):not(.react-datepicker__day--outside-month)
  .react-datepicker__day-colored-shade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #1c64ac;
}
.react-datepicker__day:not(.react-datepicker__day--selected):not(.react-datepicker__day--outside-month)
  .react-datepicker__day-colored-content {
  position: relative;
}

/* === */

.react-datepicker__day:not(.react-datepicker__day--selected):not(.react-datepicker__day--outside-month)
  .react-datepicker__day-missing-data
  .react-datepicker__day-colored-content {
  box-shadow: 0 0 0 2px inset #fffead;
}
