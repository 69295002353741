.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 12px 0;
  margin-bottom: 6px;
}

.header {
  margin: 0 !important;
}

.button {
  cursor: pointer;
}

.right {
  text-align: right;
}
