.wrapper {
  display: flex;
}
.countryCode {
  width: 30%;
  flex: 0 0 auto;
}
.phoneNumber {
  flex: 1 1 0px;
  margin-left: 0.5em;

  input {
    width: 100% !important;
  }
}
.lock {
  flex: 0 0 auto;
  margin-left: 0.5em;
}
