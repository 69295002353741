.main {
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  padding: 2em 1em 0em 1em;
  position: relative;
  margin-top: 0.5em;
  margin-bottom: 2em;
}

.header {
  position: absolute;
  top: -1em;
  left: 0.5em;
  padding: 0.5em;
  background-color: white;
}
