/* Fixed layout for smaller devices */
@media only screen and (max-width: 991px) {
  body {
    min-width: 991px !important;
    overflow-x: auto !important;
  }

  .ui.container,
  .ui.menu > .ui.container {
    width: 933px !important;
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
}

/* Menu.Item as Link (React-Router-Link) should be disabled */
.ui.menu a.item.disabled {
  pointer-events: none;
}

/* Button in loading should be disabled */
.button.loading {
  pointer-events: none !important;
}

/* Loader should be under Menu. And Menu should be under Modal.  */
.ui.menu.fixed {
  z-index: 1000;
}
.ui.loader {
  z-index: 999;
}

.ui.input.no-spinner input[type='number']::-webkit-inner-spin-button,
.ui.input.no-spinner input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.ui.input.no-spinner input[type='number'] {
  -moz-appearance: textfield;
}

/* Fix border of structured table */
.ui.celled.table tr td,
.ui.celled.table tr th {
  border-left: 1px solid rgba(34, 36, 38, 0.1) !important;
}

/* Other global styles */
.color-red {
  color: #db2828;
}
.color-yellow {
  color: #fbbd08;
}
.color-green {
  color: #21ba45;
}
.color-grey {
  color: #767676;
}
.color-black {
  color: rgba(0, 0, 0, 0.87);
}
